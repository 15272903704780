import { ActionTypes } from "../constants/actionTypes"

export const orderReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_ORDER:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return {};
        default:
            return state;
    }
}

export const ordersReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_ORDERS:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return [];
        default:
            return state;
    }
}

export const pendingOrdersListReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.PENDING_ORDER_LIST:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return [];
        default:
            return state;
    }
}