import { ActionTypes } from "../constants/actionTypes"

export const setShopDetail = (shopDetail) => {
    return {
        type: ActionTypes.SET_SHOP_DETAIL,
        payload: shopDetail
    }
}

export const setRoleDetail = (role) => {
    return {
        type: ActionTypes.SET_ROLE,
        payload: role
    }
}

export const setRoles = (roles) => {
    return {
        type: ActionTypes.SET_ROLES,
        payload: roles
    }
}

export const setMenu = (menu) => {
    return {
        type: ActionTypes.SET_MENU,
        payload: menu
    }
}

export const setLoaderView = (loaderState) => {
    return {
        type: ActionTypes.SHOW_HIDE_LOADER,
        payload: loaderState
    }
}

export const setShowOrderDetailsPopup = (popupState) => {
    return {
        type: ActionTypes.SHOW_HIDE_ORDER_DETAILS_POPUP,
        payload: popupState
    }
}

export const setShowOrderDetailsPopupButtons = (popupState) => {
    return {
        type: ActionTypes.SHOW_HIDE_ORDER_DETAILS_POPUP_BUTTONS,
        payload: popupState
    }
}

export const setShowMyProfilePopup = (popupState) => {
    return {
        type: ActionTypes.SHOW_HIDE_MY_PROFILE_POPUP,
        payload: popupState
    }
}