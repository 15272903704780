import { ActionTypes } from "../constants/actionTypes"

export const productsReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_PRODUCTS:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return [];
        default:
            return state;
    }
}

export const bulkProductsReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_BULK_PRODUCTS:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return [];
        default:
            return state;
    }
}

export const csvIdReducer = (state = null, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_CSV_ID:
            return payload;
        default:
            return state;
    }
}