export const ActionTypes = {
    SET_PRODUCTS: "SET_PRODUCTS",
    SET_BULK_PRODUCTS: "SET_BULK_PRODUCTS",
    SET_CSV_ID: "SET_CSV_ID",
    SET_ROLE: "SET_ROLE",
    SET_ROLES: "SET_ROLES",
    SET_ORDER: "SET_ORDER",
    SET_BANNERS: "SET_BANNERS",
    SET_GST_DATES: "SET_GST_DATES",
    SET_MENU: "SET_MENU",
    SET_CUSTOMERS: "SET_CUSTOMERS",
    SET_ORDERS: "SET_ORDERS",
    SET_SHOP_DETAIL: "SET_SHOP_DETAIL",
    SET_USER: "SET_USER",
    SHOW_HIDE_LOADER: "SHOW_HIDE_LOADER",
    SHOW_HIDE_ORDER_DETAILS_POPUP: "SHOW_HIDE_ORDER_DETAILS_POPUP",
    SHOW_HIDE_ORDER_DETAILS_POPUP_BUTTONS: "SHOW_HIDE_ORDER_DETAILS_POPUP_BUTTONS",
    LOGOUT_USER: "LOGOUT_USER",
    PENDING_ORDER_LIST: "PENDING_ORDER_LIST",
    SET_DASHBOARD_DETAILS: "SET_DASHBOARD_DETAILS",
    SHOW_HIDE_MY_PROFILE_POPUP: "SHOW_HIDE_MY_PROFILE_POPUP"
}