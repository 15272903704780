import { ActionTypes } from "../constants/actionTypes"

export const customerReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_CUSTOMERS:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return [];
        default:
            return state;
    }
}