import { ActionTypes } from "../constants/actionTypes"

export const bannerReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_BANNERS:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return [];
        default:
            return state;
    }
}