import { ActionTypes } from "../constants/actionTypes"

export const dashboardDetailsReducer = (state = null, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_DASHBOARD_DETAILS:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return {};
        default:
            return state;
    }
}

export const gstDatesReducer = (state = [], { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_GST_DATES:
            return payload;
        case ActionTypes.LOGOUT_USER:
            return [];
        default:
            return state;
    }
}