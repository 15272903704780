import apiService from "./api";

//Shop
export const fetchShopDetail = async (shopId) => {
    const response = await apiService.get(`/users/get_shop_data/${shopId}`);
    return response.data;
}

export const submitShopDetails = async (formData) => {
    const response = await apiService.post("/users/add_shop_data", formData);
    return response.data;
}

export const updateShopDetails = async (shopId, formData) => {
    const response = await apiService.put(`/users/update_shop_data/${shopId}`, formData);
    return response.data;
}

export const updateShopStatus = async (shopId) => {
    const response = await apiService.post(`/users/toggle_shop_open_status?shop_id=${shopId}`);
    return response.data;
}

export const fetchUserShopRoleDetail = async (shopId) => {
    const response = await apiService.get(`/users/get_user_shop_role_data?shop_id=${shopId}`);
    return response.data;
}

export const fetchTimePeriod = async () => {
    const response = await apiService.get(`/master/get_timeperiod_data`);
    return response.data;
}

export const fetchCustomFormFields = async (shopId) => {
    const response = await apiService.get(`/users/get_custom_checkout_field?shop_id=${shopId}`);
    return response.data;
}

export const fetchShopCategories = async () => {
    const response = await apiService.get(`/master/get_all_shop_categories`);
    return response.data;
}

//Roles
export const fetchRoles = async (shopId) => {
    const response = await apiService.get(`/users/get_all_roles?shop_id=${shopId}`);
    return response.data;
}

export const createRole = async (shopId, formData) => {
    const response = await apiService.post(`/users/add_role_data?shop_id=${shopId}`, formData);
    return response.data;
}

export const updateRole = async (roleId, shopId, formData) => {
    const response = await apiService.put(`/users/update_role_data/${roleId}?shop_id=${shopId}`, formData);
    return response.data;
}

export const deleteRole = async (roleId, shopId) => {
    const response = await apiService.delete(`/users/delete_role_data/${roleId}?shop_id=${shopId}`);
    return response.data;
}


//Users
export const fetchUsers = async (shopId) => {
    const response = await apiService.get(`/users/get_all_users?shop_id=${shopId}`);
    return response.data;
}

export const createUser = async (shopId, formData) => {
    const response = await apiService.post(`/users/add_user?shop_id=${shopId}`, formData);
    return response.data;
}

export const updateUser = async (userId, shopId, formData) => {
    const response = await apiService.put(`/users/update_user/${userId}?shop_id=${shopId}`, formData);
    return response.data;
}

export const deleteUser = async (userId, shopId) => {
    const response = await apiService.delete(`/users/delete_user/${userId}?shop_id=${shopId}`);
    return response.data;
}


//Address
export const fetchCountries = async () => {
    const response = await apiService.get("/master/get_country_data");
    return response.data;
}

export const fetchStates = async (countryId) => {
    const response = await apiService.get(`/master/get_state_data?country_id=${countryId}`);
    return response.data;
}

export const fetchCities = async (countryId, stateId) => {
    const response = await apiService.get(`/master/get_city_data?country_id=${countryId}&state_id=${stateId}`);
    return response.data;
}

// export const fetchPinCodes = async () => {
//     const response = await apiService.get("/master/get_pincode_data");
//     return response.data;
// }

export const fetchPinCodes = async (searchQuery) => {
    const response = await apiService.get(`/master/search_pincode?search_query=${searchQuery}`);
    return response.data;
}

export const fetchCountryCodes = async () => {
    const response = await apiService.get("/master/get_currencies_data");
    return response.data;
}


//QR Builder
export const fetchQrBuilder = async (shopId) => {
    const response = await apiService.get(`/users/get_shop_qr?shop_id=${shopId}`);
    return response.data;
}

export const saveQrBuilder = async (shopId, formData) => {
    const response = await apiService.post(`/users/add_shop_qr?shop_id=${shopId}`, formData);
    return response.data;
}

//Policies
export const fetchPolicyTypes = async () => {
    const response = await apiService.get(`/master/get_termsAndPolicies_data`);
    return response.data;
}

export const getPolicies = async (shopId) => {
    const response = await apiService.get(`/users/get_all_shop_policies?shop_id=${shopId}`);
    return response.data;
}

export const addPolicy = async (shopId, formData) => {
    const response = await apiService.post(`users/add_policy_data?shop_id=${shopId}`, formData);
    return response?.data;
}

export const updatePolicy = async (policyId, shopId, formData) => {
    const response = await apiService.put(`users/update_policy_data/${policyId}?shop_id=${shopId}`, formData);
    return response?.data;
}

export const deletePolicy = async (policyId, shopId) => {
    const response = await apiService.delete(`users/delete_policy_data/${policyId}?shop_id=${shopId}`);
    return response?.data;
}