
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SideMenu = () => {

    const menu = useSelector((state) => state?.menu);
    const shop = useSelector((state) => state?.shop);

    return (
        <div className="leftside-menu">
            <Link to="/" className="logo text-center logo-light">
                <span className="logo-lg">
                    <img src={shop?.logo ? shop?.logo : "/assets/images/logo.svg"} alt="" />
                </span>
                <span className="logo-sm">
                    <img src={shop?.logo ? shop?.logo : "/assets/images/logo.svg"} alt="" />
                </span>
            </Link>
            <Link to="/" className="logo text-center logo-dark">
                <span className="logo-lg">
                    <img src={shop?.logo ? shop?.logo : "/assets/images/logo.svg"} alt="" />
                </span>
                <span className="logo-sm">
                    <img src={shop?.logo ? shop?.logo : "/assets/images/logo.svg"} alt="" />
                </span>
            </Link>

            <div className="h-100" id="leftside-menu-container" data-simplebar>
                <ul className="side-nav">
                    {menu?.map((category, categoryIndex) => {
                        if (category?.status) {
                            return <li className="side-nav-item" key={categoryIndex}>
                                {category?.subCategory?.length > 0 ?
                                    <>
                                        <Link data-bs-toggle="collapse" to={`#${category?.path}`} aria-expanded="false" aria-controls="sidebarEcommerce" className="side-nav-link">
                                            <i className={category?.icon}></i>
                                            <span> {category?.label} </span>
                                            {category?.subCategory && <span className="menu-arrow"></span>}
                                        </Link>
                                        <div className="collapse" id={category?.path}>
                                            <ul className="side-nav-second-level">
                                                {category?.subCategory?.map((subCategory, subCategoryIndex) => {
                                                    return <li key={subCategoryIndex}>
                                                        <Link to={subCategory?.path}>{subCategory?.label}</Link>
                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                    </>
                                    :
                                    <Link to={category?.path} className="side-nav-link">
                                        <i className={category?.icon}></i>
                                        <span> {category?.label} </span>
                                    </Link>
                                }
                            </li>
                        } else {
                            return <div title="Please upgrade your plan to use this feature" className="side-nav-link cursor-not-allowed">
                                <i className={`${category?.icon} text-muted cursor-not-allowed`}></i>
                                <span className="text-muted"> {category?.label} </span>
                            </div>
                        }
                    })}
                </ul>
                <div className="clearfix"></div>
            </div>
        </div>
    )
}

export default SideMenu;