import apiService from "./api";

//Orders
export const fetchOrders = async (shopId, pageSize, pageCount) => {
    const response = await apiService.post(`orders/get_all_orders?shop_id=${shopId}&?page_count=${pageSize}&?page_no=${pageCount}`);
    return response.data;
}

export const fetchOrder = async (order_id) => {
    const response = await apiService.get(`orders/get_order_data/${order_id}`);
    return response?.data;
}

export const fetchPendingOrders = async (shopId) => {
    const response = await apiService.get(`orders/get_pending_orders?shop_id=${shopId}`);
    return response?.data;
}

export const acceptOrder = async (order_id, shop_id) => {
    const response = await apiService.get(`orders/accept_order/${order_id}?shop_id=${shop_id}`);
    return response?.data;
}

export const rejectOrder = async (order_id, shop_id) => {
    const response = await apiService.get(`orders/reject_order/${order_id}?shop_id=${shop_id}`);
    return response?.data;
}