import { combineReducers } from 'redux';
import { bulkProductsReducer, csvIdReducer, productsReducer } from './productReducer';
import { userReducer } from './userReducer';
import { loaderReducer, menuReducer, myProfilePopupReducer, orderDetailsPopupButtonsReducer, orderDetailsPopupReducer, roleReducer, rolesReducer, shopReducer } from './settingReducer';
import { orderReducer, ordersReducer, pendingOrdersListReducer } from './orderReducer';
import { dashboardDetailsReducer, gstDatesReducer } from './dashboardReducer';
import { bannerReducer } from './bannerReducer';
import { customerReducer } from './customerReducer';

const reducers = combineReducers({
    products: productsReducer,
    bulkProducts: bulkProductsReducer,
    csvId: csvIdReducer,
    user: userReducer,
    roles: rolesReducer,
    role: roleReducer,
    menu: menuReducer,
    shop: shopReducer,
    order: orderReducer,
    orders: ordersReducer,
    banners: bannerReducer,
    gstFilingDates: gstDatesReducer,
    customers: customerReducer,
    pendingOrdersList: pendingOrdersListReducer,
    loader: loaderReducer,
    orderDetailsPopup: orderDetailsPopupReducer,
    orderDetailsPopupButtons: orderDetailsPopupButtonsReducer,
    dashboard: dashboardDetailsReducer,
    profilePopup: myProfilePopupReducer
});

export default reducers;